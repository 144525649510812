<template>
    <div class="addSliderPop-slider">
        <div class="tree-con">
            <el-tree
                :data="treeData"
                :props="defaultProps"
                :highlight-current="true"
                :expand-on-click-node="false"
                node-key="id"
                :default-expanded-keys="defaultKey"
                :current-node-key="currentNodeKey"
                class="businessGroupWh-slider-tree"
                show-checkbox
                ref="tree"
            >
                <span class="slot-t-node" slot-scope="{ node, data }">
                    <div class="parent-node" v-if="data.directory_type===0">
                        <i v-if="node.expanded" class="iconfont iconshouqi" @click.stop="expandtree(node)"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai" @click.stop="expandtree(node)"></i>
                        <span v-if="node.expanded" class="iconfont iconwenjianjia-zhankai" @click.stop="expandtree(node)"></span>
                        <span v-if="!node.expanded" class="iconfont iconwenjianjia-shouqi" @click.stop="expandtree(node)"></span>
                        <span>{{ data.name }}</span>
                    </div>
                    <div class="leaf-node" v-else @click="loadTable(data.entity_id)">
                        <span class="iconfont iconlingxing"></span>
                        <span>{{ data.name }}</span>
                    </div>
                </span>
            </el-tree>
        </div>
        <div class="btn-con">
            <el-button type="primary" size="medium" @click="onSubmit">
                确定
            </el-button>
            <el-button size="medium" @click="onCancel">
                取消
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            defaultKey: [], // 默认展开节点id集合
            currentNodeKey: '', // 当前节点key
            menuVisible: false,
            currentNode: {}, // 当前节点数据
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 展开节点
        expandtree(node) {
            node.expanded = !node.expanded;
            if (node.expanded) {
                this.defaultKey = [];
                this.defaultKey.push(node.data.id);
            } else {
                this.defaultKey = [];
            }
        },
        // 拉取业务分组树
        getTree() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/directory/gettree?parent_code=0')
                .then(res => {
                    this.treeData = res;
                    const obj = this.getFirstLeafNode(res);
                    this.$emit('load-data', obj.entity_id);
                    this.defaultKey = [];
                    this.defaultKey.push(obj.id);
                    this.currentNodeKey = obj.id;
                    this.$nextTick(() => {
                        this.$refs.tree.setCurrentKey(obj.id);
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 递归查找第一个叶子节点
        getFirstLeafNode(data) {
            let obj = null;
            if (data && data.length > 0) {
                const item = data[0];
                if (item.children.length === 0) {
                    obj = item;
                } else {
                    obj = this.getFirstLeafNode(item.children);
                }
            }
            return obj;
        },
        // 新增完成更新树
        handleCallBack() {
            this.getTree();
        },
        // 加载表格
        loadTable(id) {
            this.currentNodeKey = id;
            this.$emit('load-data', id);
        },
        // 确定
        onSubmit() {
            const checkedNodes = this.$refs.tree.getCheckedNodes();
            const resultNodes = [];
            if (checkedNodes.length > 0) {
                checkedNodes.map(item => {
                    if (item.directory_type === 1) {
                        resultNodes.push(item);
                    }
                });
                this.extr.closeCall(resultNodes);
                window.hideToast();
            }
        },
        // 取消
        onCancel() {
            window.hideToast();
        },
    },
    created() {
        this.getTree();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.addSliderPop-slider
    height 100%
    background #fff
    overflow hidden
    padding .2rem
    .new-module
        height .44rem
        background #F8F8F8
        border-bottom 1px solid #D7D7D7
        line-height .44rem
        color #333
        cursor pointer
        .iconxianshi_tianjia
            margin 0 .09rem 0 .12rem
        &:hover
            color #5588F1
    .tree-con
        height calc(100% - .54rem)
        overflow-y auto
        overflow-x auto
        border 1px solid #ccc
        margin-bottom .1rem
        .slot-t-node
            line-height .38rem
            height .38rem
            .parent-node
                .iconzhankai
                    color #5588F1
                .iconshouqi
                    color #F19F26
                .iconwenjianjia-shouqi
                    color #5588F1
                .iconwenjianjia-zhankai
                    color #F19F26
                .iconfont
                    margin-right .1rem
            .leaf-node
                color #666666
                .iconlingxing
                    color #979EA7
                    margin 0 .1rem 0 0
                    font-size .14rem
    .more-menu
        width 1.7rem
        position absolute
        border 1px solid #d3d5d4
        box-shadow 0 0 .05rem #d3d5d4
        border-bottom none
        z-index 1000
        .iconfont
            margin-right .1rem
        .menu-name
            background #f8f8f8
        .el-menu
            border-right none
            .el-menu-item
                border-bottom 1px solid #f0f0f0
                height .38rem
                line-height .38rem
                &:focus
                    background-color #1577d2
                    color #fff
        .header
            background #5588F1
            color #fff
            text-align center
            font-size .16rem
            line-height .32rem!important
    .btn-con
        text-align center
</style>
<style>
.addSliderPop-slider .el-icon-caret-right:before{
    display: none!important;
}
.addSliderPop-slider .el-tree-node__content{
    height: .36rem;
}
.addSliderPop-slider .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node{
    color: #FE7903;
}
.addSliderPop-slider .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node .iconlingxing{
    color: #FE7903;
}
.addSliderPop-slider .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background-color:#E2EAF0;
}
</style>